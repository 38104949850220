import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import jaText from '@sagano/share/i18n/lang/ja';

import jaUI from '@linktivity/link-ui/lang/ja';
import enUI from '@linktivity/link-ui/lang/en';
import koUI from '@linktivity/link-ui/lang/ko';
import ztUI from '@linktivity/link-ui/lang/zt';
import zhUI from '@linktivity/link-ui/lang/zh';
import jaFileText from './lang/ja';
import enFileText from './lang/en';
import koFileText from './lang/ko';
import ztFileText from './lang/zt';
import zhFileText from './lang/zh';

export const DEFAULT_LOCALE = 'ja';
export const LOCALE_LIST = ['en', 'ja', 'ko', 'zt', 'zh'];

export const LOCALE_LANG_MAPPING: Record<string, string> = {
  en: 'en',
  ja: 'ja',
  ko: 'ko',
  zh: 'zh-hans',
  zt: 'zh-hant'
};
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const resources = {
  ja: {
    translation: {
      share: jaText,
      ...jaUI,
      ...jaFileText
    }
  },
  en: {
    translation: {
      share: jaText,
      ...enUI,
      ...enFileText
    }
  },
  ko: {
    translation: {
      share: jaText,
      ...koUI,
      ...koFileText
    }
  },
  zt: {
    translation: {
      share: jaText,
      ...ztUI,
      ...ztFileText
    }
  },
  zh: {
    translation: {
      share: jaText,
      ...zhUI,
      ...zhFileText
    }
  }
} as const;

void i18n.use(initReactI18next).init({
  lng: DEFAULT_LOCALE,
  returnNull: false,
  resources
});

export function setLocale(locale: string): string {
  void i18n.changeLanguage(locale);
  document.documentElement.setAttribute('lang', locale);
  localStorage.setItem('locale', locale);
  return locale;
}

function getLocaleParams() {
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || '';
  if (LOCALE_LIST.includes(lang)) {
    return lang;
  }
}

export function getServiceLocale(): string {
  const lang = getLocale();
  return LOCALE_LANG_MAPPING[lang];
}

export function getLocale(): string {
  return i18n.language || DEFAULT_LOCALE;
}

(() => {
  const defaultLocale =
    getLocaleParams() || localStorage.getItem('locale') || DEFAULT_LOCALE;
  setLocale(defaultLocale);
})();

export default i18n;
