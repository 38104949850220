/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError
} from 'react-router-dom';

const CancellationPolicy = lazy(
  () => import('@sagano/file/views/CancellationPolicy')
);
const Voucher = lazy(() => import('@sagano/file/views/Voucher'));
const NotFound = lazy(() => import('@sagano/share/views/NotFound'));
const Invoice = lazy(() => import('@sagano/file/views/Invoice'));
const Seat = lazy(() => import('@sagano/file/views/Seat'));
const Receipt = lazy(() => import('@sagano/file/views/Receipt'));
function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route
        path="/cancellation-policy"
        element={lazyView(<CancellationPolicy />)}
      />
      <Route
        path="invoice/:bookingId/:token/:paymentId"
        element={lazyView(<Invoice />)}
      />
      <Route path="voucher/:bookingId/:token" element={lazyView(<Voucher />)} />
      <Route path="seat/:productId/:direction" element={lazyView(<Seat />)} />
      <Route
        path="receipt/:bookingId/:token/:paymentId"
        element={lazyView(<Receipt />)}
      />
      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default router;
