export default {
  voucher: {
    title: '嵯峨野トロッコ列車電子乗車票',
    info: {
      voucherNumber: 'チケット番号',
      date: '利用日',
      time: '発車時間',
      serviceName: '列車名',
      userNumber: '乗車人数',
      seat: '座席',
      agentName: '代理店名',
      agentSubGroupName: '営業所名',
      groupName: '団体名',
      tip: '特記事項',
      notice: '注意事項',
      total: '合計 ',
      people: '名',
      station: 'トロッコ',
      seatsTips: `
      詳細は下記座席表を参照願います`,
      noticeValue: `乗車当日は発車20分前までに手続きをお願いします。
          緊急の際は075-871-3997までご連絡をお願いします。
          トロッコ列車は全車指定席。
          全車禁煙です。
          `
    },
    qrCode: {
      title: '二次元バーコード',
      tip: 'チケットの利用方法',
      step1: 'STEP01',
      step1Tip: 'こちらの乗車券をダウンロードする',
      step2: 'STEP02',
      step2Tip: '駅の窓口に発車20分前に受付を完了してください',
      step3: 'STEP03',
      step3Tip: '座席表を確認してお座り下さい'
    },
    carsTitle: '座席表',
    carsTip: '※座席番号が記載されているところが選択されている座席です。',
    arrangementPattern: {
      carName: '車両',
      car1: '1号車',
      car2: '2号車',
      car3: '3号車',
      car4: '4号車',
      car5: 'リッチ号/5号車'
    }
  },
  invoice: {
    title: '請求書',
    paymentType: {
      PREPAID: '(クレジットカード支払い)',
      COUPON: '（船車券発行）',
      MONTHLY: '（銀行振込）',
      CASH: '現金',
      OTHER: 'その他'
    },
    info: {
      title: '{{agentName}} 御中',
      tip: '毎々格別のお引き立てを賜り、誠にありがとうございます。下記の通り、ご請求申し上げます。'
    },
    infoDate: {
      COUPON: 'お支払い期限',
      PREPAID: 'お支払い期限'
    },
    bill: {
      title: 'ご請求金額（税込）',
      people: '人数合計',
      groupType: '団体区分',
      date: '利用日',
      time: '乗車時刻',
      trainName: '列車名'
    },
    table: {
      allAmount: '総額 (1)+(2)（税込）',
      cancelAllAmount: '総額 (2)+(3)（税込）',
      total: '合計',
      allPaidAmount: 'お支払い済額',
      taxDiff: '税率区分',
      tax: '消費税',
      amount: '金額（税込）',
      memo: '備考'
    },
    tax: '消費税額',
    groupType: {
      NORMAL: '一般団体',
      SCHOOL: '学生団体'
    },
    taxDetail: '税金詳細',
    agent: {
      date: '発行日',
      invoiceId: '請求番号',
      corporateNumber: '登録番号',
      postalCode: '〒',
      address: '住所',
      phone: '電話',
      fax: 'FAX'
    }
  },
  seat: {
    parenthesis: '（{{text}}）',
    header: {
      station: '구매 정보를 입력해주세요',
      seat: '차량과 좌석을 선택해주세요',
      start: '{{time}} 출발',
      confirm: '당신은 아래 좌석을 선택했습니다'
    },
    button: {
      back: '돌아가기',
      next: '다음 단계'
    },
    selectSeatType: {
      title: '좌석 선택 방식',
      auto: '자동 배정',
      manual: '수동 선택'
    },
    station: {
      date: '탑승일',
      startStation: '승차역',
      endStation: '하차역',
      startStationTip: '승차역을 선택해주세요',
      endStationTip: '하차역을 선택해주세요'
    },
    hintType: {
      OUT_OF_AVAILABLE_TERM:
        '선택한 날짜는 판매 시작 전입니다. (승차일 1개월 전부터 구매 가능합니다.)',
      END_OF_SERVICE: '오늘 운행은 종료되었습니다.'
    },
    train: {
      header: '열차를 선택해주세요',
      emptySeat: '빈 좌석'
    },
    carriage: {
      carriage1: '1호차',
      carriage2: '2호차',
      carriage3: '3호차',
      carriage4: '4호차',
      carriage5: '리치5호차',
      directionUpTitle: '↑ 토롯코 사가 방면',
      directionDownTitle: '↑ 토롯코 가메오카 방면'
    },
    baseCar: {
      front: '운전석',
      entrance: '입구'
    }
  },
  receipt: {
    title: '領収書',
    name: '御中',
    number: 'No.',
    issueData: '発行日',
    amountTips: '但、嵯峨野トロッコ利用料として\n上記正に領収いたしました。',
    price: '金額',
    priceUnit: '￥{{price}}',
    afterTax: '（税込）',
    afterTaxPrice: '税抜金額',
    taxPrice: '消費税等',
    issuer: `{{name}}\n〒{{postalCode}}\n{{address}}\n電話：{{phoneNumber}}\nFAX：{{fax}}`,
    chargeTips: '※内訳は請求書を参照願います。',
    tips: '＊A4縦に印刷し、下部の余白は切ってお使いください'
  },
  cancellationPolicy: {
    title: 'キャンセルポリシー'
  }
};
